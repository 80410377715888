/* Swiper */
#__next .swiper-slide {
  width: auto;
}

#__next .swiper-scrollbar {
  height: 10px;
}

#__next .swiper-pagination-bullet {
  background-color: gray;
}

#__next .swiper-pagination-bullet-active {
  background-color: #008e14;
}

#__next .scroll-list-swiper .swiper-button-prev,
#__next .scroll-list-swiper .swiper-button-next {
  width: 50px;
  z-index: 10;
  cursor: pointer;
}

.swiper-container-tags .swiper-button-prev,
.swiper-container-tags .swiper-button-next {
  width: 30px;
  height: 30px;
  z-index: 10;
  cursor: pointer;
  top: 23px;
}

#__next .scroll-list-swiper .swiper-button-next {
  right: 15px;
}

#__next .scroll-list-swiper .swiper-button-prev {
  left: 15px;
}

#__next .scroll-list-swiper .swiper-button-prev::before,
#__next .scroll-list-swiper .swiper-button-next::before {
  content: '';
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: var(--chakra-colors-backgroundLight);
  border: 1px solid var(--chakra-colors-gray-300);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.swiper-container-tags .swiper-button-prev::before,
.swiper-container-tags .swiper-button-next::before {
  content: '';
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #f2f2f2;
  opacity: 0.6;
  border: 1px solid var(--chakra-colors-gray-300);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.swiper-container-tags .swiper-button-prev::after,
.swiper-container-tags .swiper-button-next::after {
  content: '';
  width: 12px;
  height: 12px;
  border-top: 2px solid #008e14;
  border-right: 2px solid #008e14;
  position: absolute;
}

.swiper-container-tags .swiper-button-prev::after {
  transform: rotate(-135deg);
  margin-right: -3px;
}

.swiper-container-tags .swiper-button-next::after {
  transform: rotate(45deg);
  margin-left: -5px;
}

#__next .scroll-list-swiper .swiper-button-prev::after,
#__next .scroll-list-swiper .swiper-button-next::after {
  content: '';
  width: 10px;
  height: 10px;
  border-top: 3px solid #008e14;
  border-right: 3px solid #008e14;
  position: absolute;
}

#__next .scroll-list-swiper .swiper-button-prev::after {
  transform: rotate(-135deg);
  margin-right: -3px;
}

#__next .scroll-list-swiper .swiper-button-next::after {
  transform: rotate(45deg);
  margin-left: -5px;
}

#__next .swiper-button-disabled {
  display: none;
}

@media screen and (max-width: 639px) {
  #__next .swiper-button-prev,
  #__next .swiper-button-next {
    display: none;
  }
}

/* Custom Modal Styles */
@media (orientation: landscape) and (max-height: 639px) {
  .chakra-portal .custom-chakra-modal-content .chakra-modal__header {
    padding-top: 9rem;
  }

  .chakra-portal .custom-chakra-modal-content .chakra-modal__close-btn {
    top: 9rem;
  }
}

/* React Select（ダークモード対応） */
.chakra-container .react-select__control {
  background-color: inherit;
  height: var(--chakra-sizes-10);
  border-color: var(--chakra-colors-gray-200);
}

.chakra-container .react-select__control:hover {
  border-color: var(--chakra-colors-gray-300);
}

.chakra-container .react-select__placeholder {
  color: var(--chakra-colors-gray-400);
}

.chakra-container .react-select__menu {
  z-index: 99999;
}

.chakra-ui-dark .chakra-container .react-select__control {
  border-color: var(--chakra-colors-whiteAlpha-300);
}

.chakra-ui-dark .chakra-container .react-select__control:hover {
  border-color: var(--chakra-colors-whiteAlpha-400);
}

.chakra-ui-dark .chakra-container .react-select__placeholder {
  color: var(--chakra-colors-whiteAlpha-400);
}

.chakra-ui-dark .chakra-container .react-select__single-value {
  color: var(--chakra-colors-whiteAlpha-900);
}

.chakra-ui-dark .chakra-container .react-select__menu {
  background-color: var(--chakra-colors-gray-600);
}

.chakra-ui-dark .chakra-container .react-select__option {
  background-color: var(--chakra-colors-gray-600);
}

.chakra-ui-dark .chakra-container .react-select__option:hover {
  background-color: var(--chakra-colors-gray-500);
}

.blinking {
  -webkit-animation: blink 3s ease-in-out infinite alternate;
  -moz-animation: blink 3s ease-in-out infinite alternate;
  animation: blink 3s ease-in-out infinite alternate;
}
@-webkit-keyframes blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes blink {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
